import React from "react"
import theme from "../../core/Theme"
import Typography from "@material-ui/core/Typography"
import CardContent from "@material-ui/core/CardContent"

interface Props {
  Icon: React.ComponentType<any>
  metric: string
  description: string
}

const MetricContent = ({ Icon, metric, description }: Props) => {
  return (
    <CardContent>
      <Icon color={theme.palette.primary.main} size={110}></Icon>

      <Typography component="p" variant="h5">
        {metric}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {description}
      </Typography>
    </CardContent>
  )
}

export default MetricContent
