import React, { useState } from "react"
import AppBar from "@material-ui/core/AppBar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import MenuIcon from "@material-ui/icons/Menu"
import Gift from "@material-ui/icons/CardGiftcard"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles"
import { mainListItems } from "./Dashboard/listItems"
import Box from "@material-ui/core/Box"
import AboutDialog from "./AboutDialog"
import Button from "@material-ui/core/Button"

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "auto",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("md")]: {
        width: `100%`,
        marginLeft: drawerWidth,
        zIndex: theme.zIndex.drawer + 100,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    drawerContainer: {
      overflow: "auto",
    },
    box: {
      display: "flex",
      flexGrow: 1,
    },
  })
)

export default function ResponsiveDrawer() {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [openAbout, setOpenAbout] = useState<boolean>(false)
  const openAboutDialog = () => setOpenAbout(true)
  const closeAboutDialog = () => setOpenAbout(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const openLink = () => {
    window.open('https://www.buymeacoffee.com/thomasnall')
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>{mainListItems(openAboutDialog)}</List>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              USA Covid Statistics
            </Typography>
          </Box>
          <Button
            className="donation-button"
            onClick={openLink}
            variant="contained"
            color="default"
            endIcon={<Gift></Gift>}
          >
            Donate
          </Button>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="side drawer">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <AboutDialog close={closeAboutDialog} open={openAbout}></AboutDialog>
    </div>
  )
}
