import * as dateMath from "date-arithmetic"
import { weekdays, months } from "../api/constant-data"

/**
 * Given a date return the last 7 days
 * @param date Date object
 */
export function lastSevenDay(date: Date) {
  let arr = []
  for (let i = 0; i < 5; i++) {
    let prevDate = dateMath.subtract(date, i, "day")
    let weekday = weekdays[prevDate.getDay()]
    let fullDate = yyyyMMDD(prevDate)
    arr.push({ weekday, fullDate })
  }
  return arr.reverse()
}

/**
 * Convert date from yyyyMMDD to YYYY-MM-DD
 * @param date date in the yyyyMMDD
 */
export function convertDate(date: string) {
  const year = date.slice(0, 4)
  const month = date.slice(4, 6)
  const day = date.slice(6, 8)
  return `${year}-${month}-${day}`
}

/**
 * Given a date return the last X months
 * @param date Date objec
 */
export function lastXMonths(date: Date, mths: number) {
  let arr = []
  for (let i = 0; i < mths; i++) {
    let prevDate = dateMath.subtract(date, i, "month")
    if (i === 0) {
      // Use the day before
      prevDate = dateMath.subtract(prevDate, 1, "day")
    }
    let month = months[prevDate.getMonth()]
    let fullDate = yyyyMMDD(prevDate)
    arr.push({ month, fullDate })
  }
  return arr.reverse()
}

/**
 * Takes a Javascript date object and returns the date in the YYYYMMDD formate
 * @param date Javascript date object
 */
function yyyyMMDD(date: Date) {
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  const addZero = (num: number) => (num > 9 ? num.toString() : `0${num}`)

  let fullDate = `${year.toString()}${addZero(month)}${addZero(day)}`
  return fullDate
}

/**
 * Convert a number to a string with commas
 * @param x Any number
 */
export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

/**
 * Returns a string representation of todays date
 */
export function todaysDate() {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = today.getFullYear()
  const strDate = mm + '/' + dd + '/' + yyyy
  return strDate
}
