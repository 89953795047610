import { Step } from "react-joyride"

const steps: Step[] = [
  {
    target: ".total-cases-simple",
    content: "Info cards will provide you with COVID metrics",
    placement: "top",
  },
  {
    target: ".first-source",
    content: "All cards will provide a source citation",
    placement: "top",
  },
  // {
  //   target: ".state-covid-table",
  //   content: "All info cards have a sourced",
  //   placement: "top",
  // },
  {
    target: ".local-map",
    content: "View COVID cases near you reported by fellow users",
    placement: "top",
  },
  {
    target: ".total-cases-simple",
    content: "There are more metrics available on the desktop browser. Be sure to check it out!",
    placement: "top",
  },
  {
    target: ".donation-button",
    content: "Any support or feedback is greatly appreciated. Thanks for stopping by!",
    placement: "top",
  },

  // {
  //   target: ".A simple table representation of the near cases",
  //   content: "All info cards have a sourced",
  //   placement: "right",
  // },

//   {
//     target: ".covid-graph-month",
//     content: "All info cards have a sourced",
//     placement: "top",
//   },
  // {
  //   target: ".covid-bar-graph",
  //   content: "You can select and/or deselect metrics below the graph",
  //   placement: "top",
  // },
  // {
  //   target: ".covid-us-map",
  //   content: "There are more metrics available on the desktop browser ",
  //   placement: "top",
  // },
//   {
//     target: ".bmc-wbtn",
//     content: "All info cards have a sourced",
//     placement: "top",
//   },
]

let coffeeStep: Step = {
  target: "",
  content: "Please donate and provide feedback. Thanks for stopping by!",
  placement: "top",
}

export { steps, coffeeStep }
