export interface SourceInfo {
  name: InfoType
  source: string
  description: string
}

enum InfoType {
  nearCasesMap = "NEAR_CASES_MAP",
  nearCasesTable = "NEAR_CASES_TABLE",
  stateTableMetricS = "STATE_TABLE",
  perMonthMetrics = "PER_MONTH_METRICS",
  barMetrics = "BAR_METRICS",
  usMapMetrics = "US_MAP_METRICS",
}

const InfoCards: SourceInfo[] = [
  {
    name: InfoType.nearCasesMap,
    source: `Source: know-it-covid users`,
    description: `This map shows cases reported by fellow know-it-covid users. Note this information is informal. It is generated by know-it-covid users and does not effect metrics from different sources`,
  },
  {
    name: InfoType.nearCasesTable,
    source: `Source: know-it-covid users`,
    description: `Tablular representation from the previous map. Note this information is informal. It is generated by know-it-covid users and does not effect metrics from different sources.`,
  },
  {
    name: InfoType.stateTableMetricS,
    source: `Source: The Atlantic`,
    description: `Tablular representation of Covid metrics for each state`,
  },
  {
    name: InfoType.perMonthMetrics,
    source: `Source: The Atlantic`,
    description: `A graph representing per month Covid metrics. This table is helpful in identifying trends over time.`,
  },
  {
    name: InfoType.barMetrics,
    source: `Source: John Hopkins`,
    description: `A bar graph representing the Covid metrics per state.`,
  },
  {
    name: InfoType.usMapMetrics,
    source: `Source: John Hopkins`,
    description: `A United States Map representing the Covid metrics per state.`,
  },
]

/**
 * Get the source information about a specific card
 * @param type Info Card Title Name
 */
function getSourceInfo(type: string) {
  return InfoCards.find((ic) => ic.name === type) as SourceInfo
}

export { InfoType, getSourceInfo }
