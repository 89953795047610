import { createMuiTheme } from "@material-ui/core/styles"
import * as color from '@material-ui/core/colors'
import * as Charts from "react-chartjs-2"

const dark = 'dark'
// const dark = undefined

const theme = createMuiTheme({
    palette: {
        type: dark,
        primary: color.cyan,
        secondary: color.orange,
      }
})


// Change all  chartjs text based on the theme
Charts.defaults.global.defaultFontColor = (theme.palette.type === 'dark') ? theme.palette.common.white : theme.palette.common.black

export default theme
