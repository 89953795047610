import React, { useState, useEffect } from "react"
import { Line } from "react-chartjs-2"
import { lastXMonths, convertDate } from "../api/date"
import { dateUSvalues } from "../api/covid"
import { HistoricalDate, DayResults } from "../api/interfaces"
import Hidden from "@material-ui/core/Hidden"
interface Args {
  month: string
  metric: DayResults
}

/**
 * API key value is based on date, this method will get the key
 * @param date 
 */
function getDateKey(date: HistoricalDate) {
  const key = Object.keys(date.result)[0]
  return key
}

const creatChartData = (data?: Args[], pointRadius = 8) => {
  const chartInfo = {
    labels: data?.map((d) => d.month),
    datasets: [
      {
        label: "# of New Cases",
        fill: true,
        lineTension: 0.5,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: data?.map((d, i) => {
          if (i === 0) return 0
          return d.metric.confirmed - data[i - 1].metric.confirmed
        }),
        pointRadius,
        hidden: false,
      },
      {
        label: "# of New Deaths",
        fill: true,
        lineTension: 0.5,
        backgroundColor: "rgba(224, 36, 36, 1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: data?.map((d, i) => {
          if (i === 0) return 0
          return d.metric.deaths - data[i - 1].metric.deaths
        }),
        pointRadius,
        hidden: true,
      }
    ],
  }
  return chartInfo
}


const LineChart = () => {
  const [usChartData, setUsChartData] = useState<
    { month: string; metric: DayResults }[]
  >()

  useEffect(() => {
    /** Get the current USA Covid Values */
    const getDateInfo = async () => {
      let chartData = await Promise.all(
        lastXMonths(new Date(), 12).map(async (d, i, arr) => {
          const month = d.month.name
          const dateCovidData = await dateUSvalues(convertDate(d.fullDate))
          const key = getDateKey(dateCovidData)
          return { month, metric: dateCovidData.result[key] }
        })
      )

      setUsChartData(chartData)
    }
    getDateInfo()
  }, [])

  return (
    <div>
      <Hidden xsDown>
        {" "}
        <Line
          data={creatChartData(usChartData, 9)}
          options={{
            title: {
              display: false,
              text: "Number Of Cases",
              fontSize: 20,
            },
            legend: {
              display: true,
              position: "bottom",
            },
            responsive: true,
          }}
        />
      </Hidden>
      <Hidden smUp>
        {" "}
        <Line
          data={creatChartData(usChartData, 5)}
          options={{
            title: {
              display: false,
              text: "Number Of Cases",
              fontSize: 20,
            },
            legend: {
              display: true,
              position: "bottom",
            },
            responsive: true,
          }}
          width={50}
          height={75}
        />
      </Hidden>
    </div>
  )
}

export default LineChart
