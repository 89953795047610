import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Share from "./ShareButtons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
)

interface Props {
  open: boolean
  close: () => void
}

const AboutDialog = ({ open, close }: Props) => {
  const classes = useStyles()
  // const handleOpen = () => {}

  const handleClose = () => {
    close()
  }

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle id="simple-dialog-title">Know IT Covid</DialogTitle>
      <DialogContent dividers>
        <Fade in={open}>
          <div>
            <Typography>
              Welcome to Know-IT-Covid (KIC). KIC provides you with up-to-date
              Covid data and metrics. <br />
              <br /> Our goal is to provide all Americans with the proper tools
              to make inform decisions. <br />
              <br />
              Hopefully this App will be a contribution against the Covid-19
              Pandemic.
            </Typography>
          </div>
        </Fade>
      </DialogContent>
      <DialogActions>
        <Share></Share>
      </DialogActions>
    </Dialog>
  )
}

export default AboutDialog
