import React from "react"
import Card from "@material-ui/core/Card"
import Header from "./Header"
import MetricContent from "./MetricContent"
import mdiIcons from "mdi-react"


interface Props {
  title: string
  Icon: React.ComponentType<mdiIcons.MdiReactIconProps>
  metric: string
  date: string
  dataSource: string
  description: string
}

const SimpleStatCard = (props: Props) => {

  return (
    <Card>
      <Header
        title={props.title}
        date={props.date}
        dataSource={props.dataSource}
      ></Header>
      <MetricContent
        Icon={props.Icon}
        metric={props.metric}
        description={props.description}
      ></MetricContent>
    </Card>
  )
}

export default SimpleStatCard
