import React, { useEffect, useState } from "react"
import Chart from "react-chartjs-2"
import { stateMetrics } from "../api/covid"
import { State } from "../api/interfaces"
// @ts-ignore: Unreachable code error
import * as ChartGeo from "chartjs-chart-geo"
import Hidden from "@material-ui/core/Hidden"

const USPageMap = () => {
  const [data, setData] = useState<State.TopoJSON>()

  useEffect(() => {
    const getMap = async () => {
      const call = await fetch("https://unpkg.com/us-atlas/states-10m.json")
      const us = await call.json()
      const metrics = await stateMetrics()

      const nation = ChartGeo.topojson.feature(us, us.objects.nation)
        .features[0]
      const states = ChartGeo.topojson.feature(us, us.objects.states).features
      const chartInfo = {
        type: "choropleth",
        data: {
          labels: states.map((d: any) => d.properties.name),
          datasets: [
            {
              label: "States",
              outline: nation,
              data: states.map((d: any) => ({
                feature: d,
                // value: metrics.find(m => m.state === d.properties.name)
                value: metrics
                  .filter((m) => m.state === d.properties.name)
                  .map((s) => {
                    return Math.round(
                      (s.deathsPerOneMillion / s.casesPerOneMillion) * 1000
                    )
                  })[0],
              })),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scale: {
            projection: "albersUsa",
          },
          geo: {
            colorScale: {
              display: true,
              position: "bottom",
              quantize: 5,
              legend: {
                position: "bottom-right",
              },
            },
          },
        },
      }
      setData(chartInfo)
    }
    getMap()
  }, [])

  return (
    <div>
      {/* <Title>Deaths per 1000 Cases</Title> */}
      {data === undefined ? (
        <div>Can you see me?</div>
      ) : (
        <div>
          <Hidden xsDown>
            <Chart
              type={data.type}
              data={data.data}
              options={data.options}
            ></Chart>
          </Hidden>
          <Hidden smUp>
            <Chart
              type={data.type}
              data={data.data}
              options={data.options}
              height={65}
              width={50}
            ></Chart>
          </Hidden>
        </div>
      )}
    </div>
  )
}

export default USPageMap
