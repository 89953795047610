import React from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import AboutIcon from "mdi-react/AboutIcon"

export const mainListItems = (openDialog: () => void) => (
  <div>
    <ListItem key={'about'} button>
      <ListItemIcon onClick={openDialog}>
        <AboutIcon onClick={openDialog} />
      </ListItemIcon>
      <ListItemText primary="About"  onClick={openDialog}/>
    </ListItem>
  </div>
)
