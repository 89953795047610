import React, { useState, useEffect } from "react"
import { DataGrid, ColDef } from "@material-ui/data-grid"
import { StateValues } from "../api/interfaces"
import { curDataAllStates } from "../api/covid"
import { Grid } from "@material-ui/core"
import { states as stateNames } from "../api/constant-data/states-list"

interface DataRows {
  id: number
  states: string
  positiveCases: number
  deaths: number
  tests: number
}

const columns: ColDef[] = [
  {
    field: "states",
    headerName: "State",
    width: 150,
  },
  {
    field: "positiveCases",
    headerName: "Positive Cases",
    type: "number",
    width: 150,
  },
  { field: "deaths", headerName: "Deaths", type: "number", width: 130 },
  { field: "tests", headerName: "Test Totals", type: "number", width: 130 },
  {
    field: "recovered",
    headerName: "Recovered",
    type: "number",
    width: 130,
  },
  { field: "population", headerName: "Population", type: "number", width: 130 },
]

function createColumns(states: StateValues[]): DataRows[] {
  const rows = states.map((state, i) => {
    return {
      id: i,
      states:
        stateNames.find((s) => s.abbreviation === state.state)?.name ||
        state.state,
      positiveCases: state.cases,
      deaths: state.deaths,
      tests: state.tests,
      recovered: state.recovered,
      population: state.population,
    }
  })
  return rows
}

const DataTable = () => {
  const [allCurStateData, setAllCurStateData] = useState<DataRows[]>([])

  useEffect(() => {
    /** Get the current USA Covid Values */
    const getStateInfo = async () => {
      const states = await curDataAllStates()
      const cols = createColumns(states)

      setAllCurStateData(cols)
    }
    getStateInfo()
  }, [])
  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <Grid item={true} xs={12} md={12} lg={12}>
        <DataGrid
          rows={allCurStateData}
          columns={columns}
          pageSize={5}
          autoHeight
          showToolbar
        />
      </Grid>
    </div>
  )
}

export default DataTable
