import React, { useState, useEffect } from "react"
import GoogleMapReact from "google-map-react"
import { usePosition } from "use-position"
import Virus from "mdi-react/VirusIcon"
import Human from "mdi-react/HumanHandsdownIcon"
import { getLocalCases } from "../api/covid"
import { Google, Case } from "../api/interfaces"
import { MdiReactIconProps } from "mdi-react"
import Badge from "@material-ui/core/Badge"

const Marker = (props: {
  [key: string]: any
  Icon: React.ComponentType<MdiReactIconProps>
  color: string
  numOfPoints: number
}) => {
  let icn =
    props.numOfPoints > 1 ? (
      <Badge badgeContent={props.numOfPoints} color="primary">
        <props.Icon color={props.color}></props.Icon>
      </Badge>
    ) : (
      <props.Icon color={props.color}></props.Icon>
    )
  return icn
}

interface Props {
  caseNum: number
  updateCases: (cases: Case[]) => void
}

const Map = ({ caseNum, updateCases }: Props) => {
  const { latitude, longitude } = usePosition(false)
  const [cases, setCases] = useState<Case[]>()
  const [bounds, setBounds] = useState<Google.Bounds>()
  

  useEffect(() => {
    const update = async () => {
      if (bounds === undefined) return
      let { nw, se } = bounds
      const points = await getLocalCases(
        { lat: nw.lat, lon: nw.lng },
        { lat: se.lat, lon: se.lng }
      )
      setCases(points)
    }
    update()
    // eslint-disable-next-line
  }, [caseNum])

  const getCases = async (bnds: Google.Bounds) => {
    setBounds(bnds)
    let { nw, se } = bnds

    const points = await getLocalCases(
      { lat: nw.lat, lon: nw.lng },
      { lat: se.lat, lon: se.lng }
    )
    updateCases(points)
    setCases(points)
  }

  return (
    // Important! Always set the container height explicitly
    <div>
      <div style={{ height: "45vh", width: "100%" }}>
        {latitude && longitude ? (
          <GoogleMapReact
            defaultCenter={{
              lat: latitude,
              lng: longitude,
            }}
            defaultZoom={12}
            onChange={(map) => getCases(map.bounds)}
          >
            <Marker
              Icon={Human}
              lat={latitude}
              lng={longitude}
              numOfPoints={1}
              text="My Marker"
              color={"indigo"}
            />
            {cases?.map((c, i) => (
              <Marker
                Icon={Virus}
                lat={c.lat}
                lng={c.lon}
                numOfPoints={c.counter}
                text="Covid case"
                key={i}
                color={"red"}
              ></Marker>
            ))}
          </GoogleMapReact>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}

export default Map
