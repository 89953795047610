import React, { useState } from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import Fab from "@material-ui/core/Fab"
import NavigationIcon from "@material-ui/icons/Add"
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import NewCase from "./NewCase"
import { usePosition } from "use-position"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      left: 10
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
)
interface Props {
  newCase: () => void
}

const FabCovid = ({ newCase }: Props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { latitude, longitude } = usePosition(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    newCase()
    setOpen(false)
  }

  return (
    <div>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="add"
        className={classes.fab}
        onClick={handleOpen}
      >
        <NavigationIcon className={classes.extendedIcon} />
        Add New Case
      </Fab>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle id="simple-dialog-title">New Covid Case</DialogTitle>
        <DialogContent dividers>
          <Fade in={open}>
            <NewCase
              close={handleClose}
              curLocation={{ lat: (latitude || 0), lon: (longitude || 0) }}
            ></NewCase>
          </Fade>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default FabCovid
