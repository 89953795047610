import React, { useState } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Typography from "@material-ui/core/Typography"
import CardHeader from "@material-ui/core/CardHeader"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { Tooltip } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import InfoIcon from "@material-ui/icons/Info"
import Avatar from "@material-ui/core/Avatar"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import clsx from "clsx"
import Collapse from "@material-ui/core/Collapse"
import { SourceInfo } from '../../core/SourceInfo'

interface Props {
  title: string
  date: string
  dataSource: SourceInfo
  Icon: React.ComponentType<any>
  Content: JSX.Element
  Button?: JSX.Element
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
        color: theme.palette.secondary.main,
      },
  })
)

const BigCard = (props: Props) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar>
            <props.Icon className={classes.avatar}></props.Icon>
          </Avatar>
        }
        action={
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                // disableFocusListener
                // disableHoverListener
                // disableTouchListener
                title={props.dataSource.source}
              >
                <IconButton className="first-source" onClick={handleTooltipOpen} aria-label="source-info">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        }
        title={props.title}
        subheader={props.date}
      />
      <CardContent>{props.Content}</CardContent>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton> */}
        {props.Button}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{props.dataSource.description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default BigCard
