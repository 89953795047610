import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import useOnclickOutside from "react-cool-onclickoutside"
import {
  OutlinedInput as Input,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
interface lld {
  lat: number
  lon: number
  place_id?: string
}
interface Props {
  location: (loc: lld) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pin: {
      display: "flex",
    },
  })
)

const PlacesAutocomplete = ({ location }: Props) => {
  const classes = useStyles()
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })
  
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value)
  }

  const handleSelect = ({ description, place_id }: any) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false)
    clearSuggestions()

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {
        // console.log(results)
        return getLatLng(results[0])
      })
      .then(({ lat, lng }) => {
        // set lat long
        location({lat, lon: lng, place_id: place_id })
      })
      .catch((error) => {
        console.log("😱 Error: ", error)
      })
  }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <div>
          <ListItem key={place_id} onClick={handleSelect(suggestion)}>
            <ListItemText>{main_text}</ListItemText>
            <small>{secondary_text}</small>
          </ListItem>
          <Divider />
        </div>
      )
    })

  return (
    <div ref={ref}>
      <Input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter Address or Location Name..."
        className={classes.pin}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <List>{renderSuggestions()}</List>}
    </div>
  )
}

export default PlacesAutocomplete
