import { latlon } from './interfaces'

/** Urls to a public COVID API the data is collected by `The Atlantic` */
export const urls = {
    //  The covidtracking app is discontinued
    deprecated: {
        HISTORICAL_US_VALUES: `https://api.covidtracking.com/v1/us/daily.json`,
        CURRENT_US_VALUES: `https://api.covidtracking.com/v1/us/current.json`,
        SPECIFIC_DATE_US_VALUES: (date: string) => `https://api.covidtracking.com/v1/us/${date}.json`,
        STATE_METADATA: `https://api.covidtracking.com/v1/states/info.json`,
        SPECIFIC_STATE_METADATA: (state: string) => `https://api.covidtracking.com/v1/states/${state}/info.json`,
        HISTORIC_DATA_ALL_STATES: `https://api.covidtracking.com/v1/states/daily.json`,
        CURRENT_DATA_ALL_STATES: `https://api.covidtracking.com/v1/states/current.json`,
        CURRENT_DATA_SINGLE_STATE: (state: string) => `https://api.covidtracking.com/v1/states/${state}/current.json`,
        HISTORICAL_DATA_SINGLE_STATE: (state: string) => `https://api.covidtracking.com/v1/states/${state}/daily.json`,
        SPECIFIC_DATE_SINGLE_STATE: (state: string, date: string) => `https://api.covidtracking.com/v1/states/${state}/${date}.json`,
        // All the urls will be removed after March 7 2021
    },
    CURRENT_US_VALUES: `https://disease.sh/v3/covid-19/countries/usa?strict=false`,
    HISTORICAL_US_VALUES: `https://api.covidtracking.com/v1/us/daily.json`,
    // SPECIFIC_DATE_US_VALUES: (date: string) => `https://api.covidtracking.com/v1/us/${date}.json`,
    SPECIFIC_DATE_US_VALUES: (date: string) => `https://covidapi.info/api/v1/country/usa/${date}`,
    STATE_METADATA: `https://api.covidtracking.com/v1/states/info.json`,
    SPECIFIC_STATE_METADATA: (state: string) => `https://api.covidtracking.com/v1/states/${state}/info.json`,
    HISTORIC_DATA_ALL_STATES: `https://api.covidtracking.com/v1/states/daily.json`,
    CURRENT_DATA_ALL_STATES: `https://disease.sh/v3/covid-19/states`,
    CURRENT_DATA_SINGLE_STATE: (state: string) => `https://api.covidtracking.com/v1/states/${state}/current.json`,
    HISTORICAL_DATA_SINGLE_STATE: (state: string) => `https://api.covidtracking.com/v1/states/${state}/daily.json`,
    SPECIFIC_DATE_SINGLE_STATE: (state: string, date: string) => `https://api.covidtracking.com/v1/states/${state}/${date}.json`,


    ALL_STATE_METRICS: 'https://disease.sh/v3/covid-19/states',
    // ADD_NEW_CASE: `http://localhost:3001/cases`,
    ADD_NEW_CASE: `https://knowitcovidapi.herokuapp.com/cases`,
    // GET_LOCAL_CASES: (nw: latlon, se: latlon) => `http://localhost:3001/cases/byExtent?topLeftLat=${nw.lat}&bottomRightLat=${se.lat}&topLeftLon=${nw.lon}&bottomRightLon=${se.lon}`,
    GET_LOCAL_CASES: (nw: latlon, se: latlon) => `https://knowitcovidapi.herokuapp.com/cases/byExtent?topLeftLat=${nw.lat}&bottomRightLat=${se.lat}&topLeftLon=${nw.lon}&bottomRightLon=${se.lon}`,
}