import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import Facebook from "mdi-react/FacebookIcon"
import Pinterest from "mdi-react/PinterestIcon"
import Linkedin from "mdi-react/LinkedinIcon"
import Mail from "mdi-react/MailIcon"
import Twitter from "mdi-react/TwitterIcon"
import Tooltip from "@material-ui/core/Tooltip"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
)

const ShareButtons = () => {
  const classes = useStyles()
  const facebook = () => {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=https://www.knowitcovid.com/"
    )
  }
  const twitter = () => {
    window.open(
      "https://twitter.com/intent/tweet?url=https://www.knowitcovid.com/&text=A Covid information page."
    )
  }
  const pinterest = () => {
    window.open(
      "https://pinterest.com/pin/create/button/?url=https://www.knowitcovid.com/&media=&description=A Covid information page."
    )
  }
  const linkedin = () => {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=https://www.knowitcovid.com/&title=&summary=A Covid information page.&source="
    )
  }
  const mail = () => {
    window.open(
      "mailto:info@example.com?&subject=&body=https://www.knowitcovid.com/ A Covid information page."
    )
  }

  return (
    <div className={classes.root}>
      <Tooltip title="Share via Facebook">
        <IconButton onClick={facebook}>
          <Facebook color="#4267B2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share via Twitter">
        <IconButton onClick={twitter}>
          <Twitter color="#1DA1F2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share via Pinterest">
        <IconButton onClick={pinterest}>
          <Pinterest color="#E60023" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share via LinkedIn">
        <IconButton onClick={linkedin}>
          <Linkedin color="#0072b1" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share via Mail">
        <IconButton onClick={mail}>
          <Mail />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default ShareButtons
