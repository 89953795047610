import React, { useState } from "react"
import CardHeader from "@material-ui/core/CardHeader"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { Tooltip } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import InfoIcon from "@material-ui/icons/Info"

interface Props {
  title: string
  date: string
  dataSource: string
}

const Header = ({ title, date, dataSource }: Props) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  return (
    <CardHeader
      action={
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                style: { pointerEvents: 'none' }
              }}
              onClose={handleTooltipClose}
              open={open}
              // disableFocusListener
              // disableHoverListener
              // disableTouchListener
              title={dataSource}
            >
              <IconButton onClick={handleTooltipOpen} aria-label="settings">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
        </ClickAwayListener>
      }
      title={title}
      subheader={date}
    />
  )
}

export default Header
