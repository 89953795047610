import * as React from "react"
import { DataGrid, ColDef } from "@material-ui/data-grid"
import Title from "./Dashboard/Title"

interface Cases {
  lat: number
  lon: number
  counter: number
}
interface Props {
  cases?: Cases[]
}

const CasesTable = ({ cases }: Props) => {
  const columns: ColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "lat", headerName: "Latitude", width: 130, type: "number" },
    { field: "lon", headerName: "Longitude", width: 130, type: "number" },
    {
      field: "numCases",
      headerName: "Num of Cases",
      width: 160,
      type: "number",
    },
    // { field: "Link", headerName: "Longitude", width: 130, type: "number" },
  ]

  const rows = cases?.map((c, i) => {
    return {
      id: i,
      lat: c.lat,
      lon: c.lon,
      numCases: c.counter,
    }
  })
  const sum = rows?.reduce((total, cur) => {
    return total + cur.numCases
  }, 0)
  return (
    <div style={{ height: 400, width: "100%" }}>
      <Title>Reported cases near you total: {sum}</Title>
      <DataGrid
        rows={rows || []}
        columns={columns}
        pageSize={5}
        // checkboxSelection
      />
    </div>
  )
}

export default CasesTable
