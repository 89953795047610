import React, { useEffect, useState } from "react"
import { Bar as HorizontalBar } from "react-chartjs-2"
import { stateMetrics } from "../api/covid"
import Hidden from "@material-ui/core/Hidden"

const colors = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"]

interface StateData {
  data: number[]
  backgroundColor: string[]
  labels: string[]
}

const BarGraph = () => {
  const [stateChartData, setUsChartData] = useState<StateData>()

  useEffect(() => {
    /** Get the current USA Covid Values */
    const getDateInfo = async () => {
      const states = await stateMetrics()
      const chartInfo = states
        .sort((a, b) => a.state.localeCompare(b.state)) // Sort by alphabet
        .filter((s) => s.casesPerOneMillion > 0) // Remove states not recorded
        .reduce(
          (prev, cur, i) => {
            prev.data.push(cur.casesPerOneMillion)
            prev.labels.push(cur.state)
            prev.backgroundColor.push(colors[i % 5])
            return prev
          },
          {
            data: [] as number[],
            backgroundColor: [] as string[],
            labels: [] as string[],
          }
        )
      setUsChartData(chartInfo)
    }
    getDateInfo()
  }, [])
  const data = {
    labels: stateChartData?.labels,
    datasets: [
      {
        label: "Covid Cases Per Million",
        backgroundColor: stateChartData?.backgroundColor,
        data: stateChartData?.data,
      },
    ],
  }

  const options = {
    legend: { display: false },
    title: {
      display: true,
      text: "Positive Covid Cases Per 1 Million Citizens",
    },
  }
  return (
    <div>
      {/* Do not render this when smaller than sm */}
      <Hidden xsDown>
        <HorizontalBar data={data} width={100} height={40} options={options} />
      </Hidden>
      {/* Do not render this when larger than md */}
      <Hidden smUp>
        <HorizontalBar data={data} width={50} height={75} options={options} />
      </Hidden>
    </div>
  )
}

export default BarGraph
