import { ThemeProvider } from "@material-ui/core"
// import React, { useState, useEffect } from "react"
import React from 'react'
import "./App.css"
import Dashboard from "./components/Dashboard/Dashboard"
import theme from "./core/Theme"
import Joyride from "react-joyride"
import { steps } from "./core/Tours"

const App = () => {
  // const [steps, setSteps] = useState(initSteps)
  // useEffect(() => {
  //   const getCoffee = async () => {
  //     window.addEventListener("load", function () {
  //       let coffee = document.getElementById("bmc-wbtn")
  //       if (coffee) {
  //         let newStep = coffeeStep
  //         newStep.target = coffee
  //         const newSteps = steps.map((s) => s)
  //         newSteps.push(newStep)
  //         setSteps(newSteps)
  //       }
  //     })
  //   }
  //   getCoffee()
  // }, [])
  return (
    <ThemeProvider theme={theme}>
      <Joyride
        steps={steps}
        continuous={true}
        showProgress={true}
        scrollOffset={100}
      />
      <Dashboard></Dashboard>
    </ThemeProvider>
  )
}

export default App
