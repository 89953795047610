import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import { UsaValues, Case } from "../../api/interfaces"
import { curUSvalues } from "../../api/covid"
import { numberWithCommas, todaysDate } from "../../api/date"
import Map from "../Map"
import StateTable from "../StateTable"
// Icons
import Skull from "mdi-react/SkullCrossbonesIcon"
import BarIcon from "mdi-react/BarChartIcon"
import Hospital from "mdi-react/AccountHeartIcon"
import Virus from "mdi-react/VirusIcon"
import Test from "mdi-react/TestTubeIcon"
import FabCovid from "../FabButton"
import Bars from "../BarGraph"
import USA from "../UsMap"
import LineChart from "../LineChart"
import BigCard from "../Cards/BigCard"

import SimpleMetric from "../Cards/SimpleMetric"
import Account from "mdi-react/AccountCircleIcon"
import TableIcon from "mdi-react/TableLargeIcon"
import CasesTable from "../CasesTable"

import Hidden from "@material-ui/core/Hidden"

import { getSourceInfo, InfoType } from "../../core/SourceInfo"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}))

export default function Dashboard() {
  const [sumUS, setSumUs] = useState({} as UsaValues)
  const [newCase, setNewCase] = useState<number>(0)
  const [cases, setCases] = useState<Case[]>()
  const today = todaysDate()

  const updateCases = (c: Case[]) => {
    setCases(c)
  }

  const iterate = () => {
    console.log("iterate")
    setNewCase(newCase + 1)
  }

  useEffect(() => {
    /** Get the current USA Covid Values */
    const getUSA = async () => {
      let data = await curUSvalues()
      setSumUs(data)
    }
    getUSA()
  })
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container={true} spacing={3}>
        {/* Total Number of Cases */}
        <Grid className="total-cases-simple" item xs={6} md={4} lg={3}>
          <SimpleMetric
            title={"Positive Cases"}
            Icon={Virus}
            metric={numberWithCommas(sumUS.cases || 0)}
            dataSource={"Source: John Hopkins"}
            description="This number represents the total number of positive case in the United States"
            date={today}
          ></SimpleMetric>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <SimpleMetric
            title={"Total Deaths"}
            Icon={Skull}
            metric={numberWithCommas(sumUS.deaths || 0)}
            dataSource={"Source: John Hopkins"}
            description="This number represents the total number of deaths related to Covid in the United States"
            date={today}
          ></SimpleMetric>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <SimpleMetric
            title={"Recovered Patients"}
            Icon={Hospital}
            metric={numberWithCommas(sumUS.recovered || 0)}
            dataSource={"Source: John Hopkins"}
            description="This number represents the total number of recovered Covid patients in the United States"
            date={today}
          ></SimpleMetric>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <SimpleMetric
            title={"Test Totals"}
            Icon={Test}
            metric={numberWithCommas(sumUS.tests || 0)}
            dataSource={"Source: John Hopkins"}
            description="This number represents the total number of test administered in the United States"
            date={today}
          ></SimpleMetric>
        </Grid>
        <Hidden mdDown>
          <Grid className="local-map" item xs={12} md={8} lg={6}>
            <BigCard
              title={"Covid Cases near you..."}
              date={today}
              dataSource={getSourceInfo(InfoType.nearCasesMap)}
              Icon={Account}
              Content={<Map updateCases={updateCases} caseNum={newCase}></Map>}
              Button={<FabCovid newCase={iterate}></FabCovid>}
            ></BigCard>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid className="local-map-table" item xs={12} md={8} lg={6}>
            <BigCard
              title={"Covid Cases near you..."}
              date={today}
              dataSource={getSourceInfo(InfoType.nearCasesTable)}
              Icon={Account}
              Content={<CasesTable cases={cases}></CasesTable>}
              // Button={<FabCovid newCase={iterate}></FabCovid>}
            ></BigCard>
          </Grid>
        </Hidden>
        <Grid className="state-covid-table" item xs={12} md={12} lg={12}>
          <BigCard
            title={"State Covid Numbers"}
            date={today}
            dataSource={getSourceInfo(InfoType.stateTableMetricS)}
            Icon={TableIcon}
            Content={<StateTable />}
          ></BigCard>
        </Grid>
        <Grid className="covid-graph-month" item xs={12} md={12} lg={12}>
          <BigCard
            title={"Covid Metrics per Month"}
            date={today}
            dataSource={getSourceInfo(InfoType.perMonthMetrics)}
            Icon={Virus}
            Content={<LineChart />}
          ></BigCard>
        </Grid>
        <Grid className="covid-bar-graph" item xs={12} md={12} lg={12}>
          <BigCard
            title={"Covid Case Per Million"}
            date={today}
            dataSource={getSourceInfo(InfoType.barMetrics)}
            Icon={BarIcon}
            Content={<Bars />}
          ></BigCard>
        </Grid>
        <Grid className="covid-us-map" item xs={12} md={12} lg={12}>
          <BigCard
            title={"Deaths per 1000 Cases"}
            date={today}
            dataSource={getSourceInfo(InfoType.usMapMetrics)}
            Icon={BarIcon}
            Content={<USA />}
          ></BigCard>
        </Grid>
      </Grid>
    </div>
  )
}
