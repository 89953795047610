import axios from "axios"
import { urls } from "./urls"
import {
  OrigUsaValues,
  HistoricalDate,
  UsaValues,
  StateMetadata,
  OrigStateValues,
  StateValues,
  latlon,
  StateMetrics,
  Case,
} from "./interfaces"

/**
 * Post data to a URL
 * @param url URL to POST to
 * @param body Request body of POST
 */
const postData = async (url: string, body: any) => {
  let data = await axios.post(url, body)
  return data
}

/**
 * Get data from a url
 * @param url A URL must be a GET method
 */
const getData = async (url: string) => {
  let { data } = await axios.get(url)
  return data
}

/**
 * Get the Current USA COVID values
 */
export const curUSvalues = async () => {
  let data = await getData(urls.CURRENT_US_VALUES)
  return data as UsaValues
}

/**
 * Load a COVID case
 * @param location Latitude and Longitude location
 */
export const postCovidCase = async (location: latlon) => {
  let data = await postData(urls.ADD_NEW_CASE, location)
  return data
}

/**
 * Get the local COVID cases by a bounding box
 * @param location Latitude and Longitude location
 */
export const getLocalCases = async (nw: latlon, se: latlon) => {
  let data = await getData(urls.GET_LOCAL_CASES(nw, se))
  return data as Case[]
}

/**
 * Get the Historical USA COVID values
 */
export const historicalUSvalues = async () => {
  let data = await getData(urls.HISTORICAL_US_VALUES)
  return data as OrigUsaValues[]
}

/**
 * Get the USA COVID values for a specific date
 * @param date A date in number format
 */
export const dateUSvalues = async (date: string) => {
  let data = await getData(urls.SPECIFIC_DATE_US_VALUES(date))
  return data as HistoricalDate
}

/**
 * Get basic information about states, including notes about our methodology and the websites we use to check for data.
 */
export const stateMetadata = async () => {
  let data = await getData(urls.STATE_METADATA)
  return data as StateMetadata[]
}

/**
 * Get metadata about a specific state
 * @param date A data in number format
 */
export const specificStateMetadata = async (state: string) => {
  let data = await getData(urls.SPECIFIC_STATE_METADATA(state))
  return data as StateMetadata
}

/**
 * Get lists of all COVID data available for every state since tracking started.
 */
export const historicalDataAllStates = async () => {
  let data = await getData(urls.HISTORIC_DATA_ALL_STATES)
  return data as OrigStateValues[]
}

/**
 * Get the most recent COVID data for every state. The current value may be different than today.
 */
export const curDataAllStates = async () => {
  let data = await getData(urls.CURRENT_DATA_ALL_STATES)
  return data as StateValues[]
}

/**
 * Get the most recent COVID data for a single state. The current value may be different than today. Use lower-case state codes in the URL.
 * @param date A data in number format
 */
export const curDataSingleState = async (state: string) => {
  let data = await getData(urls.CURRENT_DATA_SINGLE_STATE(state))
  return data as OrigStateValues
}

/**
 * Get all COVID data for a single state. Use lower-case state codes in the URL.
 * @param date A data in number format
 */
export const historicalDataSingleState = async (state: string) => {
  let data = await getData(urls.HISTORICAL_DATA_SINGLE_STATE(state))
  return data as OrigStateValues[]
}

/**
 * Get all COVID values for a single state on a specific date. Use lower-case state codes in the URL.
 * @param state A USA state
 * @param date A data in number format
 */
export const specificDateSingleState = async (state: string, date: string) => {
  let data = await getData(urls.SPECIFIC_DATE_SINGLE_STATE(state, date))
  return data as OrigStateValues
}

/**
 * Get all the state metric data
 */
export const stateMetrics = async () => {
  let data = await getData(urls.ALL_STATE_METRICS)
  return data as StateMetrics[]
}
