import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Virus from "mdi-react/VirusIcon"
import AutoComplete from "./AutoComplete"
import { latlon } from "../api/interfaces"
import { postCovidCase } from "../api/covid"
import Cancel from "mdi-react/CancelBoldIcon"

interface Props {
  close: () => void
  curLocation: latlon
}

const NewCase = ({ close, curLocation }: Props) => {
  const [checked, setChecked] = React.useState(false)
  const [location, setLocation] = useState<latlon>()

  const updateLocation = (ll: latlon) => {
    setLocation(ll)
  }
  const uploadLocation = async (ll: latlon) => {
    postCovidCase(ll).then(d => close())
  }
  const addNewCase = () => {
    // console.log(curLocation)
    if (checked) {
      if (curLocation.lat === 0 && curLocation.lon === 0) return
      uploadLocation(curLocation)
    } else if (location) {
      uploadLocation(location)
    }
  }

  return (
    <React.Fragment>
      <AutoComplete location={updateLocation}></AutoComplete>
      {/* <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
            color="primary"
            checkedIcon={<CheckBoxIcon fontSize="large" />}
            name="checkedI"
            checked={checked}
            onChange={handleChange}
          />
        }
        label="Use Current Location"
      /> */}
      <Button
        // variant="outlined"
        color="inherit"
        size="large"
        startIcon={<Cancel />}
        onClick={close}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<Virus />}
        onClick={addNewCase}
      >
        Add New Case
      </Button>
    </React.Fragment>
  )
}

export default NewCase
